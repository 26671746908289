import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { catchError, retry, tap, timeout } from "rxjs/operators";

import { NavController } from "@ionic/angular";

import { Router } from "@angular/router";
import { ToastServiceService } from "../SERVICE/toastService/toast-service.service";
import { CmnServiceService } from "../SERVICE/cmnService/cmn-service.service";
import { AuthServiceService } from "../SERVICE/authService/auth-service.service";

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthServiceService,
    private navCtrl: NavController,
    private cmnService: CmnServiceService,
    private toast: ToastServiceService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let currentUser = this.authService.currentUserValue;

    if (currentUser && currentUser.api_token) {
      if (request.url.includes("waiter_call")) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.api_token}`,
            "Content-Type": "application/json",
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.api_token}`,
            "Content-Type": "application/json",
          },
        });
      }
    } else {
      if (request.url.includes("waiter_call")) {
        request = request.clone({
          setHeaders: {
            "Content-Type": "application/json",
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            "Content-Type": "application/json",
          },
        });
      }
    }

    return next.handle(request).pipe(
      /* to retry and timeout 
     retry(2),
     timeout(100),  */

      tap((event: HttpEvent<any>) => {
        if (
          event instanceof HttpResponse &&
          (event.status === 200 || event.status === 201)
        ) {
          if (!event?.body?.success) {
            if (event?.body?.message) {
              //this.cmnService.showError(event?.body?.message);
              this.toast.showError(event?.body?.message);
            }
          }
        }
      }),
      catchError((errResponse: HttpErrorResponse) => {
        if (errResponse.status === 401) {
          if (errResponse?.error.message == "Unauthenticated.") {
            // this.navCtrl.navigateBack('login');
            // localStorage.removeItem('cu_');
            // localStorage.removeItem('rc_');
            // localStorage.removeItem('_pp');
            // localStorage.removeItem('_pnf');
            this.authService.currentUserSubject.next(null);
          }
        }

        if (errResponse.status === 403) {
          //data: "Balance Security Check Failed"
          //security_flag: false;
          if (!errResponse?.error?.security_flag) {
            // this.cmnService.showToast(
            //   'Your account is blocked. Kindly connect support for further help. '
            // );
          }
        }
        const err = errResponse?.error?.error;
        this.cmnService.hideLoader();
        return throwError(errResponse);
      })
    );
  }
}
