import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { map } from "rxjs/operators";
import { CmnServiceService } from "../cmnService/cmn-service.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { SERVER_URL } from "src/app/constants/constant.config";
@Injectable({
  providedIn: "root",
})
export class AuthServiceService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(
    private http: HttpClient,
    private cmnService: CmnServiceService,
    private router: Router
  ) {
    let user = localStorage.getItem("cu_");
    if (user) {
      this.currentUserSubject = new BehaviorSubject<any>(
        JSON.parse(this.cmnService.decrypt(user))
      );
      this.currentUser = this.currentUserSubject.asObservable();
    } else {
      this.currentUserSubject = new BehaviorSubject<any>(null);
    }
  }

  public get currentUserValue() {
    if (this.currentUserSubject) return this.currentUserSubject?.value;
  }

  login(loginData) {
    return this.http.post(SERVER_URL + "v1/login", loginData).pipe(
      map(
        (loginResponse: any) => {
          if (loginResponse) {
            localStorage.setItem(
              "cu_",
              this.cmnService.encrypt(JSON.stringify(loginResponse))
            );
            localStorage.setItem("user_temp", JSON.stringify(loginResponse));

            this.currentUserSubject.next(loginResponse);
            let tableInfo = JSON.parse(localStorage.getItem("tableInfo"));
            if (tableInfo && tableInfo?.table_number >= 0)
              this.router.navigateByUrl("home");
            else {
              if (localStorage.getItem("loginFrom") == "nav")
                this.router.navigateByUrl("home");
              if (localStorage.getItem("loginFrom") == "start-a-tab")
                this.router.navigateByUrl("select-table");
            }
          }

          return loginResponse;
        },
        (err) => {
          if (err.status == 401)
            this.cmnService.showError("Invalid Username or Password");
          return err;
        }
      )
    );
  }

  signup(data) {
    return this.http.post(SERVER_URL + "v1/register", data).pipe(
      map(
        (loginResponse: any) => {
          if (loginResponse) {
            localStorage.setItem(
              "cu_",
              this.cmnService.encrypt(JSON.stringify(loginResponse))
            );
            localStorage.setItem("user_temp", JSON.stringify(loginResponse));

            this.currentUserSubject.next(loginResponse);
            const tableInfo = JSON.parse(localStorage.getItem("tableInfo"));
            if (tableInfo.table_number >= 0) {
              this.router.navigateByUrl("home");
            } else {
              this.router.navigateByUrl("select-table");
            }
          }

          return loginResponse;
        },
        (err) => {
          return err;
        }
      )
    );
  }

  logout() {
    localStorage.removeItem("cu_");
    localStorage.removeItem("user_temp");
    let tableInfo = {
      location_id: JSON.parse(localStorage.getItem("tableInfo")).location_id,
      table_number: -1,
    };
    localStorage.setItem("tableInfo", JSON.stringify(tableInfo));
    this.currentUserSubject.next(null);
    this.router.navigateByUrl("login");
    //  window.location.reload();
  }
}
