import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardsGuard } from "./guards/auth-guards.guard";
import { ScanCodePage } from "./scan-code/scan-code.page";
import { ScanCompComponent } from "./scan-comp/scan-comp.component";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "",
    //loadChildren: () =>
    //  import('./scanner/scanner.module').then((m) => m.ScannerPageModule),
    //canActivate: [AuthGuardsGuard],
    redirectTo: "asklocation",
    pathMatch: "full",
  },
  // {
  //   path: ':id',
  //   loadChildren: () =>
  //     import('./scanner/scanner.module').then((m) => m.ScannerPageModule),
  //   canActivate: [AuthGuardsGuard],
  // },

  {
    path: "item-detail",
    loadChildren: () =>
      import("./item-detail/item-detail.module").then(
        (m) => m.ItemDetailPageModule
      ),
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("./checkout/checkout.module").then((m) => m.CheckoutPageModule),
  },
  {
    path: "cart",
    loadChildren: () =>
      import("./cart/cart.module").then((m) => m.CartPageModule),
  },
  {
    path: "category",
    loadChildren: () =>
      import("./category/category.module").then((m) => m.CategoryPageModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
    canActivate: [AuthGuardsGuard],
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignupPageModule),
    canActivate: [AuthGuardsGuard],
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
    canActivate: [AuthGuardsGuard],
  },
  {
    path: "guest-checkout",
    loadChildren: () =>
      import("./guest-checkout/guest-checkout.module").then(
        (m) => m.GuestCheckoutPageModule
      ),
  },
  {
    path: "pay-bill",
    loadChildren: () =>
      import("./pay-bill/pay-bill.module").then((m) => m.PayBillPageModule),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./payment/payment.module").then((m) => m.PaymentPageModule),
  },
  {
    path: "split-bill",
    loadChildren: () =>
      import("./split-bill/split-bill.module").then(
        (m) => m.SplitBillPageModule
      ),
  },
  {
    path: "split-bill-main",
    loadChildren: () =>
      import("./split-bill-main/split-bill-main.module").then(
        (m) => m.SplitBillMainPageModule
      ),
  },
  {
    path: "order-history",
    loadChildren: () =>
      import("./order-history/order-history.module").then(
        (m) => m.OrderHistoryPageModule
      ),
  },
  {
    path: "order-detail",
    loadChildren: () =>
      import("./order-detail/order-detail.module").then(
        (m) => m.OrderDetailPageModule
      ),
  },
  {
    path: "help",
    loadChildren: () =>
      import("./help/help.module").then((m) => m.HelpPageModule),
  },
  {
    path: "myaccount",
    loadChildren: () =>
      import("./myaccount/myaccount.module").then((m) => m.MyaccountPageModule),
  },
  {
    path: "payment-method",
    loadChildren: () =>
      import("./payment-method/payment-method.module").then(
        (m) => m.PaymentMethodPageModule
      ),
  },
  {
    path: "add-new-card",
    loadChildren: () =>
      import("./add-new-card/add-new-card.module").then(
        (m) => m.AddNewCardPageModule
      ),
  },
  {
    path: "callserver",
    loadChildren: () =>
      import("./callserver/callserver.module").then(
        (m) => m.CallserverPageModule
      ),
  },
  {
    path: "call-server-model",
    loadChildren: () =>
      import("./call-server-model/call-server-model.module").then(
        (m) => m.CallServerModelPageModule
      ),
  },
  // {
  //   path: 'scanner',
  //   loadChildren: () =>
  //     import('./scanner/scanner.module').then((m) => m.ScannerPageModule),
  //   canActivate: [AuthGuardsGuard],
  // },
  {
    path: "nearby",
    loadChildren: () =>
      import("./nearby/nearby.module").then((m) => m.NearbyPageModule),
  },
  {
    path: "select-table",
    loadChildren: () =>
      import("./select-table/select-table.module").then(
        (m) => m.SelectTablePageModule
      ),
    canActivate: [AuthGuardsGuard],
  },
  {
    path: "custom-tip",
    loadChildren: () =>
      import("./custom-tip/custom-tip.module").then(
        (m) => m.CustomTipPageModule
      ),
  },
  {
    path: "terms-and-condition",
    loadChildren: () =>
      import("./terms-and-condition/terms-and-condition.module").then(
        (m) => m.TermsAndConditionPageModule
      ),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: "asklocation",
    loadChildren: () =>
      import("./asklocation/asklocation.module").then(
        (m) => m.AsklocationPageModule
      ),
    canActivate: [AuthGuardsGuard],
  },
  {
    path: "restaurant/:id",
    loadChildren: () =>
      import("./restaurant-menu/restaurant-menu.module").then(
        (m) => m.RestaurantMenuPageModule
      ),
  },
  {
    path: "",
    component: ScanCodePage,
    // loadChildren: () =>
    //   import('./scan-code/scan-code.module').then((m) => m.ScanCodePageModule),
  },
  {
    path: "scan",
    component: ScanCodePage,
    // loadChildren: () =>
    //   import('./scan-code/scan-code.module').then((m) => m.ScanCodePageModule),
  },
  {
    path: "**",
    component: ScanCodePage,
    // loadChildren: () =>
    //   import('./scan-code/scan-code.module').then((m) => m.ScanCodePageModule),
  },
  {
    path: "*path",
    component: ScanCodePage,
    // loadChildren: () =>
    //   import('./scan-code/scan-code.module').then((m) => m.ScanCodePageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
