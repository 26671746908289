import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorInterceptor } from './interceptors/http-interceptor.interceptor';
import { CommonModule, DatePipe } from '@angular/common';
import { ModalPopupComponent } from './modal-popup/modal-popup.component';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { PusherServiceService } from './pusher-service.service';
import { ScanCodePageModule } from './scan-code/scan-code.module';
import { ScanCodePage } from './scan-code/scan-code.page';
import { ScanCompComponent } from './scan-comp/scan-comp.component';


@NgModule({
  declarations: [AppComponent, ModalPopupComponent, ScanCodePage , ScanCompComponent],
  entryComponents: [ScanCompComponent],
  imports: [
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    NgxMaskIonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ScanCodePageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi: true,
    },
    DatePipe,
    PusherServiceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
