import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { SERVER_URL } from "src/app/constants/constant.config";

@Injectable({
  providedIn: "root",
})
export class CmnServiceService {
  selectedMenu = "details";
  constructor(
    private tc: ToastController,
    private loadingController: LoadingController,
    private http: HttpClient
  ) {}

  decrypt(data) {
    if (data) {
      return atob(data);
    }
    return "";
  }

  encrypt(data) {
    if (data) {
      return btoa(data);
    }
    return "";
  }

  async showSuccess(msg) {
    const toast = await this.tc.create({
      message: msg,
      duration: 2000,
      color: "success",
    });
    toast.present();
  }

  getMenu() {
    let tableInfo = JSON.parse(localStorage.getItem("tableInfo"));

    return this.http.get(SERVER_URL + "v1/locations/" + tableInfo?.location_id);
  }

  async showError(err) {
    const toast = await this.tc.create({
      message: err,
      duration: 2000,
      color: "danger",
    });
    toast.present();
  }
  async showWarning(msg) {
    const toast = await this.tc.create({
      message: msg,
      duration: 2000,
      color: "warning",
    });
    toast.present();
  }

  async showLoader() {
    const loading = await this.loadingController.create({
      spinner: "lines",
      translucent: true,
      cssClass: "custom-class custom-loading",
    });
    return await loading.present();
  }

  async hideLoader() {
    await this.loadingController.dismiss();
  }

  async showToastWithButton(msg, icon?) {
    const toast = await this.tc.create({
      header: "",
      message: msg,
      position: "top",
      duration: 2000,
      cssClass: "toast-message",
      buttons: [
        {
          side: "start",
          icon: "checkmark-circle-outline",
          text: "",
          handler: () => {},
        },
        {
          icon: "close-outline",
          text: "",
          role: "cancel",
          handler: () => {
            toast.dismiss();
          },
        },
      ],
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
  }

  taxRate() {
    let tax_rate = Number(localStorage.getItem("tax_rate"));
    if (tax_rate) return tax_rate;
    else return 0;
  }

  setMenuItem(menu) {
    localStorage.setItem("menuId", menu?.id);
    localStorage.setItem("logoUrl", menu?.logo_url);
    localStorage.setItem("tax_rate", menu.tax_rate);
    localStorage.setItem("auto_gratuity_rate", menu.auto_gratuity_rate);
    localStorage.setItem("resName", menu.name);
  }

  autoGratuityRate() {
    let auto_gratuity_rate = Number(localStorage.getItem("auto_gratuity_rate"));
    if (auto_gratuity_rate >= 0) return auto_gratuity_rate;
    else return 0;
  }

  currentDate() {
    let dtToday = new Date();

    let month: any = dtToday.getMonth() + 1;
    let day: any = dtToday.getDate();
    let year = dtToday.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    return year + "-" + month + "-" + day;
  }
}
