import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { environment } from "src/environments/environment";
import { CmnServiceService } from "./SERVICE/cmnService/cmn-service.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private modal: ModalController,
    private route: Router,
    public cmnService: CmnServiceService,
    private toastCtrl: ToastController,
    private seUpdate: SwUpdate,
    private alertCtrl: AlertController
  ) {}
  ngOnInit(): void {
    // firebase.initializeApp(environment.firebase);
    this.platform.ready().then(() => {
      // your other plugins code...
      this.configureBkBtnprocess();
    });

    this.seUpdate.available.subscribe((res: any) => {
      if (
        confirm("New version is available, do you want to reload to update?")
      ) {
        this.seUpdate.activateUpdate().then(() => document.location.reload());
      }
    });

    window.addEventListener("beforeinstallprompt", function (e) {
      e.preventDefault();
      return false;
    });
  }

  configureBkBtnprocess() {
    window.onpopstate = async (evt) => {
      const alert = await this.alertCtrl.getTop();

      if (alert) {
        alert.dismiss();
        return;
      }
      if (this.route.url == "/home") {
      }

      if (this.cmnService.selectedMenu == "payments")
        this.cmnService.selectedMenu = "details";
      if (this.modal.getTop()) {
        this.modal.dismiss();
      }
    };
  }
}
