// dev;
// export const SERVER_URL = 'https://apidev.orderex.app/api/';

import { environment } from "src/environments/environment";

export const VERSION = environment.version;

//production

export const SERVER_URL = environment.apiURL;

// release notes
// 1.0.3 ==>  404 issue
// 1.0.4 ==> table number issue
// 1.0.5 ==> table number issue

//1.0.6 ==> 1)table number label issue in cart 2) close success dialog issue 3) "New Variation" is being shown (hidden now)
//  4) Start a tab button Issue 5) Added Close icon on top of item details dialog 6) Checkout issue
