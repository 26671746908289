import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SERVER_URL } from "../constants/constant.config";

@Component({
  selector: "app-scan-code",
  templateUrl: "./scan-code.page.html",
  styleUrls: ["./scan-code.page.scss"],
})
export class ScanCodePage implements OnInit {
  showEmpty = false;
  sub: any;
  showLoader = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.sub = window.location.pathname.split("/")[1];

    if (this.sub?.length === 16) {
      this.getDataFromString(this.sub);
    } else {
      this.showEmpty = true;
    }
  }

  onScanCodeClick() {
    this.router.navigateByUrl("scanner");
  }

  getDataFromString(s) {
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      qr_code: s,
    };

    this.showLoader = true;
    this.http.post(SERVER_URL + "v2/qr_code", data).subscribe(
      async (res: any) => {
        this.showLoader = false;
        // await this.cmnService.hideLoader();
        localStorage.setItem("tableInfo", JSON.stringify(res));

        this.router.navigateByUrl("home");
      },
      async (err) => {
        this.showLoader = false;
        this.showEmpty = true;
        // await this.cmnService.hideLoader();
      }
    );
  }
}
