import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
declare const Pusher: any;
@Injectable({
  providedIn: "root",
})
export class PusherServiceService {
  channel;
  constructor() {
    // prod key - bc17faeaf7bf59cb5242
    // dev key - 0f1134a35a2395c38527
    var pusher = new Pusher(environment.pusher_key, {
      cluster: "mt1",
    });
    let userData = JSON.parse(localStorage.getItem("user_temp"));
    this.channel = pusher.subscribe("user." + userData?.id);
  }
  public init() {
    return this.channel;
  }
}
