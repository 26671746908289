import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastServiceService {
  constructor() {}

  showSuccess(msg) {
    // this.toastr.success('Success!', msg);
  }

  showError(msg) {
    // this.toastr.error('Oops!', msg);
  }
}
