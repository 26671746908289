import {
  Component,
  HostListener,
  Input,
  OnInit,
  OnDestroy,
} from "@angular/core";

import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-modal-popup",
  templateUrl: "./modal-popup.component.html",
  styleUrls: ["./modal-popup.component.scss"],
})
export class ModalPopupComponent implements OnInit, OnDestroy {
  popup = "checkout";
  auto_gratuity_rate = 0;
  @Input() type = "checkout";
  logoUrl = "";
  resName = "";
  constructor(private modal: ModalController) {}

  ngOnInit() {
    const modalState = {
      modal: true,
      desc: "fake state for our modal",
    };
    history.pushState(modalState, null);
  }
  ionViewWillEnter() {
    this.logoUrl = localStorage.getItem("logoUrl");
    this.resName = localStorage.getItem("resName");
    this.auto_gratuity_rate = Number(
      localStorage.getItem("auto_gratuity_rate")
    );
  }
  @HostListener("window:popstate", ["$event"])
  onButtonClick(type) {
    this.modal.dismiss(type);
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }
}
